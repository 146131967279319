
@font-face {
	font-family: 'BentoSans-Light';
	src: url('../assets/fonts/LibreFranklin-Regular.ttf') format('truetype'),
}

@font-face {
	font-family: 'BentoSans-Regular';
	src: url('../assets/fonts/LibreFranklin-Regular.ttf') format('truetype'),
}

@font-face {
	font-family: 'BentoSans-Bold';
	src: url('../assets/fonts/LibreFranklin-Bold.ttf') format('truetype'),
}



