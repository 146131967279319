
/* Breakpoints
===========================================*/

$smartphone-portrait: 320px;
$smartphone-landscape: 480px;

$phablet: 640px;
$phablet-landscape: 736px;

$tablet-portrait: 768px;
$tablet-landscape: 1024px;

$desktop-small: 1280px;
$desktop-medium: 1440px;
$desktop-large: 1900px;

/* Fonts
===========================================*/

$font-content-light: 'BentoSans-Light', Arial, sans-serif;
$font-content-regular: 'BentoSans-Regular', Arial, sans-serif;
$font-content-bold: 'BentoSans-Bold', Arial, sans-serif;

/* Colors
===========================================*/

// CI //

$black: #000000;
$white: #FFFFFF;

$blue: #0a2240;
$light-blue: #00a1e0;
$pink: #aa0050;
$orange: #ff681d;
$yellow: #ffb81d;
$purple: #665ec7;

$red: #e31f2c;
$green: #00ca84;

// TEXT //

$font-primary: #858d9d;
$font-secondary: #FFFFFF;
$font-title: #0a2240;
$font-utility: #d8d8d8;
$font-breadcrumb: #979797;

// BORDERS //

$border: #979797;
$border-transparent: rgba(255,255,255,.5);
$border-error: #e31f2c;

// BACKGROUNDS //

$background-grey: #F5F5F5;
$background-opacity: rgba(255, 255, 255, 0.5);
$modal-opacity: rgba(0, 0, 0, 0.9);

/* Gradients
===========================================*/

$gradient-orange: linear-gradient(to right, #F18851, #E75926);
$gradient-pink: linear-gradient(to right, #a32254, #871e3b);
$gradient-blue: linear-gradient(to right, #0d2c50, #06172e);
$btn-gradient-pink: linear-gradient(to right, #a32254, #871e3b);
$btn-gradient-blue: linear-gradient(to right, #50b5e5, #398acf);
$btn-gradient-gray: linear-gradient(to right, #919aa9, #62697a);

/* Box shadow
===========================================*/

$menu-shadow: 0px 5px 10px 0px rgba(0,0,0,0.4);
$section-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);;
$card-shadow: 0px 0px 8px 4px rgba(0,0,0,0.1);;
