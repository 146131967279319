
@import "../../../scss/main.scss";

.article-card {

	box-shadow: $card-shadow;
	display: flex;
	flex-direction: column;
	height: 100%;

	&__image {

		max-height: 300px;
		position: relative;
		overflow: hidden;

		& img {

			transition: all .2s ease-in-out; 

			&:hover {
				transform: scale(1.1);
			}

		}

		& .tag {
			position: absolute;
			top: 20px;
			right: 0;
			z-index: 10;
		}

		&--video {

			& .icon {
				width: 80px;
				height: 80px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				z-index: 10;

				&:hover {
					transform: translateX(-50%) translateY(-50%) scale(1.1);
				}
				
			}

		}

	}

	&__icon {
		width: 80px;
		height: 80px;
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		cursor: pointer;
	}

	&__content {
		
		padding: 20px;
		background: $white;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media screen and (min-width: $tablet-portrait) {
			padding: 40px;
		}

	}

}
