
@import "../../../scss/main.scss";

.accordion {

	overflow: hidden;

	& input {
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	& input:checked {

		~ .accordion__answer {

			max-height: 100vh;
			padding: 5px 0 20px 0;

		}

		~ .accordion__plus-minus {

			& .accordion__plus-minus-vertical {
			  transition: all 0.5s ease-in-out;
			  transform: rotate(90deg);
			}

			& .accordion__plus-minus-horizontal {
			  transition: all 0.5s ease-in-out;
			  transform: rotate(90deg);
			  opacity: 0;
			}

		  }

	}

	&__item {
		width: 100%;
		overflow: hidden;
		border-bottom: 1px solid $white;
		position: relative;
	}
	
	&__label {
		display: inline-block;
		font-family: $font-content-regular;
		font-size: 18px;
		line-height: 30px;
		color: $white;
		padding: 18px 50px 18px 18px;
		display: flex;
		justify-content: space-between;
		background: transparent;
		font-weight: bold;
		cursor: pointer;
		position: relative;
		z-index: 2;

		@media screen and (min-width: $tablet-landscape) {
			padding: 18px 60px 18px 18px;
		}
		
	}

	&__plus-minus {
		height: 20px;
		width: 20px;
		position: absolute;
		display: block;
		top: 18px;
		right: 18px;

		@media screen and (min-width: $tablet-landscape) {
			height: 30px;
			width: 30px;
		}
	}

	&__plus-minus-vertical {
		position: absolute;
		background-color: $white;
		width: 1px;
		height: 20px;
		left: 50%;
		margin-left: -0.5px;
		top: 0;
		transition: all 0.5s ease-in-out;
		transform: rotate(-90deg);

		@media screen and (min-width: $tablet-landscape) {
			height: 30px;
		}

	}

	&__plus-minus-horizontal {
		position: absolute;
		background-color: $white;
		width: 20px;
		height: 1px;
		left: 50%;
		margin-left: -10px;
		top: 50%;
		margin-top: -0.5px;
		transition: all 0.5s ease-in-out;
		transform: rotate(-90deg);
		opacity: 1;

		@media screen and (min-width: $tablet-landscape) {
			width: 30px;
			margin-left: -15px;
		}

	}

	&__answer {
		max-height: 0;
		padding: 0;
		transition: all .35s;
	}

	&__content {

		padding: 18px;
		font-family: $font-content-regular;
		font-size: 16px;
		line-height: 28px;
		letter-spacing: 0.02em;
		color: $white;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 18px;
			line-height: 30px;
		}
		
	}

}
