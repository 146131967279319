
@import "../../../scss/main.scss";

.filter {

	box-shadow: $section-shadow;
	position: relative;
	z-index: 10;
	padding: 50px 0 20px 0;

	@media screen and (min-width: $tablet-portrait) {
		padding: 80px 0 40px 0;
	}

	& .content {

		padding-right: 0;

		@media screen and (min-width: $tablet-portrait) {
			padding: 0 100px;
		}

	}

	&__scroll {
		height: 20px;
		overflow: hidden;
	}

	&__list {
		list-style: none;
		display: flex;
		margin: 0;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding-top: 2px;
		height: 30px;
	}

	&__item {

		padding-right: 20px;
		margin-right: 20px;
		border-right: 1px solid $border;
		cursor: pointer;
		font-family: $font-content-bold;
		color: $font-primary;
		font-size: 14px;
		line-height: 18px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: 0;

		@media screen and (min-width: $tablet-portrait) {
			padding-right: 40px;
			margin-right: 40px;
			font-size: 16px;
			line-height: 20px;
		}

		&:last-of-type {
			border: 0 none;
		}

		&:hover {
			color: $pink;
		}

		&--active {
			color: $pink;
		}

	}
	
	
}
