
@import '../../../scss/main.scss';

.banner-carousel {

	position: relative;

	& .swiper-button-prev,
	& .swiper-button-next {

		display: none;

		@media screen and (min-width: $tablet-portrait) {
			display: flex;
			bottom: 30px;
			top: auto;
			right: 40px;
			left: auto;
		}

		@media screen and (min-width: $tablet-landscape) {
			bottom: 40px;
		}
	}

	& .swiper-button-prev {

		@media screen and (min-width: $tablet-portrait) {
			right: 120px;
		}

		@media screen and (min-width: $tablet-landscape) {
			right: 140px;
		}

	}

}
