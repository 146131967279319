
@import '../../../scss/main.scss';

.map {

	height: 100%;
	width: 100%;

	& iframe {

		min-height: 200px;

		@media screen and (min-width: $tablet-portrait) {
			min-height: 400px;
		}

	}

}
