
@import '../../../scss/main.scss';

.slide-counter {

	display: flex;
	align-items: baseline;

	@media screen and (min-width: $tablet-portrait) {
		margin-top: 40px;
	}

	&__item {

		font-family: $font-content-regular;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.1em;
		color: $pink;
		margin-left: 10px;
		padding-left: 10px;
		border-left: 1px solid $pink;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 16px;
			line-height: 16px;
		}

		&--active {

			font-family: $font-content-bold;
			padding: 0;
			margin: 0;
			border: 0 none;

			@media screen and (min-width: $tablet-landscape) {
				font-size: 40px;
				line-height: 22px;
			}

		}

	}

}
