
@import "../../../scss/main.scss";

.info-card {

	box-shadow: $card-shadow;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	margin: 10px 0;

	@media screen and (min-width: $smartphone-landscape) {
		width: auto;
		margin: 0;
	}

	&__image {
		max-height: 270px;
		position: relative;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	&__content {
		
		padding: 15px;
		background: $white;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media screen and (min-width: $tablet-portrait) {
			padding: 20px;
		}

		& p {
			margin-bottom: 0;
		}

	}

}
