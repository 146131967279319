
@import '../../../scss/main.scss';

.nav-button {

	width: 40px;
	height: 40px;
	background-image: $btn-gradient-pink;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	@media screen and (min-width: $tablet-portrait) {
		width: 60px;
		height: 60px;
	}

	&:hover {
		background-image: $btn-gradient-blue;
		transition: all .4s ease-in-out;
	}
	
	&:after {

		font-family: swiper-icons;
		font-size: 15px;
		color: white;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 18px;
		}

	}

	&--prev {

		margin-right: 15px;

		@media screen and (min-width: $tablet-portrait) {
			margin-right: 50px;
		}

		&:after {
			content: 'previous';
		}

	}

	&--next {

		&:after {
			content: 'next';
		}
		
	}

}
