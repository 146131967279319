
@import '../../../scss/main.scss';

.cookie-policy {
    
	width: 100%;
	background-color: $white;
	z-index: 100;
	position: fixed;
	bottom: 0;
	padding: 10px;
	transition: opacity 450ms ease-in;
	display: block;
	opacity: 0;
	transform:scale(0);
	box-shadow: $card-shadow;

	@media screen and (min-width: $tablet-landscape) {
		padding: 15px 20px;
	}

	&--open {
		opacity: 1;
		transform:scale(1);
	}

	&__content {

		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		& p {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 0;

			@media screen and (min-width: $tablet-landscape) {
				font-size: 16px;
				line-height: 24px;
			}

		}

	}


	&__close {

		margin-left: 15px;
		z-index: 100;
		position: relative;

		& input[type=checkbox] {
			width: 25px;
			height: 25px;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
			opacity: 0;
			z-index: 10;
			-webkit-touch-callout: none;
		}

		& button {

			position: relative;
			cursor: pointer;
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: transparent;

			&:hover {
				background-position: 100% 0;
				transition: all .4s ease-in-out;
			}

			&:before, 
			&:after {
				content: ' ';
				height: 25px;
				width: 2px;
				background-color: $blue;
			  }

			  &:before {
				transform: rotate(45deg);
			  }

			  &:after {
				transform: rotate(-45deg);
			  }

		}
		
	}

}
