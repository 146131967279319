
main {

	margin-top: 68px;

	@media screen and (min-width: $tablet-landscape) {
        margin-top: 98px;
	}
	
}

.content-wrapper {

	padding: 55px 0;
	position: relative;

	@media screen and (min-width: $tablet-landscape) {
        padding: 80px 0;
	}

	&--gray {
		background: $background-grey;
	}

	&--blue {

		background: $blue;

		& h2,
		& p,
		& .link  {
			color: $white;
		}

		& .link {

			color: $white;

			&:after {
				background: $white;
			}

		}

		& .link--download {

			&:before {
				background: url(../assets/icons/icon-download-white.png);
			}

		}
		
	}

}

.content-spacer {

	width: 100%;
	height: 55px;

	@media screen and (min-width: $tablet-landscape) {
        height: 80px;
	}

}

.content {
	
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 20px;

	@media screen and (min-width: $tablet-portrait) {
        padding: 0 100px;
	}
}

.fullwidth-content {

	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 20px;

	@media screen and (min-width: $tablet-portrait) {
        padding: 0 40px;
	}

}

.scroll {

	position: relative;
	top: -68px;

	@media screen and (min-width: $tablet-portrait) {
        top: -98px;
	}

}
