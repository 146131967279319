
@import '../../../scss/main.scss';

.banner {

	position: relative;
	height: 460px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;

	@media screen and (min-width: $tablet-portrait) {
		height: 390px;
	}

	@media screen and (min-width: $tablet-landscape) {
		height: 520px;
	}

	@media screen and (min-width: $desktop-medium) {
		height: 660px;
	}

	&__content {

		position: relative;
		z-index: 10;

		@media screen and (min-width: $tablet-portrait) {
			width: 60%;
		}

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
		}

	}

	&__title {

		position: relative;

		&:before {

			@media screen and (min-width: $tablet-portrait) {
				content: '';
				width: 40px;
				height: 40px;
				background-image: url(../../../assets/icons/icon-plus.png);
				background-repeat: no-repeat;
				background-size: contain;
				display: block;
				position: absolute;
				top: -40px;
				left: -40px;
			}

			@media screen and (min-width: $tablet-landscape) {
				content: '';
				width: 50px;
				height: 50px;
				top: -50px;
				left: -50px;
			}

		}

	}

	&__text {
		font-size: 22px;
		line-height: 30px;
		color: $white;
		margin-bottom: 35px;
	}

	&__background {
		position: absolute;
		top: 0;
		z-index: 9;
		width: 100%;
		height: 100%;
	}

	&__image {

		display: flex;
		justify-content: flex-end;

		@media screen and (min-width: $tablet-portrait) {
			display: block;
		}

		& img {

			width: auto;
			height: 100%;

			@media screen and (min-width: $tablet-portrait) {
				width: 100%;
				height: auto;
			}

		}

	}

	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.6;
  		mix-blend-mode: multiply;
  		background-color: $blue;
	}

	&__pattern {

		max-width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media screen and (min-width: $tablet-portrait) {
			max-width: 40%;
		}

		& img {

			width: 350%;
			height: auto;

			@media screen and (min-width: $tablet-portrait) {
				width: 300%;
				height: auto;
			}

			@media screen and (min-width: $tablet-landscape) {
				width: 200%;
				height: auto;
			}

			@media screen and (min-width: $desktop-medium) {
				width: 150%;
				height: auto;
			}
			

		}

	}

}
