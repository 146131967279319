
@import '../../../scss/main.scss';

.milestone-card {

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;

	&__date {

		position: relative;
		font-family: $font-content-bold;
		font-size: 78px;
		color: $light-blue;
		line-height: 78px;
		margin-bottom: 15px;
		width: 0;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 138px;
			line-height: 138px;
			margin-bottom: 25px;
		}

	}

	&__title {

		font-family: $font-content-bold;
		font-size: 16px;
		color: $white;
		line-height: 26px;
		margin-bottom: 35px;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 18px;
			line-height: 30px;
			margin-bottom: 60px;
		}

	}

	&__description {
		font-family: $font-content-bold;
		font-size: 28px;
		color: $white;
		line-height: 34px;
		margin-bottom: 30px;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 40px;
			line-height: 44px;
			margin-bottom: 20px;
		}

	}
	
}
