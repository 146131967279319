
@import '../../../scss/main.scss';

.history {

	position: relative;
	padding: 55px 0;
	
	@media screen and (min-width: $tablet-landscape) {
		padding: 80px 0;
	}

	& .content {

		@media screen and (min-width: $tablet-landscape) {
			display: flex;
			justify-content: flex-end;
		}

	}

	&__content {

		position: relative;
		z-index: 10;

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
		}

		& h2,
		& p {
			color: $white;
		}

		& .link {

			color: $white;

			&:after {
				background: $white;
			}

		}

	}

	&__expanded {
		margin-top: 40px;
	}

	&__background {
		position: absolute;
		top: 0;
		z-index: 9;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__image {

		display: flex;
		justify-content: flex-end;
		background-image: $gradient-blue;
		background-blend-mode: soft-light, normal;
		width: 100%;
		height: 100%;

		@media screen and (min-width: $tablet-portrait) {
			display: block;
		}

		& img {

			width: auto;
			height: 100%;
			opacity: 0.5;
			mix-blend-mode: overlay;
			-webkit-filter: blur(10px);
			filter: blur(10px);

			@media screen and (min-width: $tablet-portrait) {
				width: 100%;
				height: auto;
			}

		}

	}

	&__pattern {

		display: none;

		@media screen and (min-width: $tablet-landscape) {
			max-width: 40%;
			height: 100%;
			position: absolute;
			top: -100px;
			left: 0;
			z-index: 10;
			display: flex;
			align-items: flex-start;
    		justify-content: flex-end;
		}

		& img {


			@media screen and (min-width: $tablet-landscape) {
				width: 200%;
				height: auto;
			}

			@media screen and (min-width: $desktop-medium) {
				width: 150%;
				height: auto;
			}
			

		}

	}
	
}
