
@import '../../../scss/main.scss';

.header {
    
	width: 100%;
	background-color: $blue;
	box-shadow: $menu-shadow;
	z-index: 100;
	position: fixed;
	top: 0;
	padding: 10px 0;

	@media screen and (min-width: $tablet-landscape) {
		padding: 20px 0;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__nav-toggle {

		display: block;
		position: relative;

		@media screen and (min-width: $tablet-landscape) {
			display: none;
		}

		& input[type=checkbox] {

			display: block;
			width: 23px;
			height: 23px;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
			opacity: 0;
			z-index: 2;
			-webkit-touch-callout: none;

			&:checked ~ span {
				opacity: 1;
				transform: rotate(45deg) translate(-6px, -10px);
			} 

			&:checked ~ span:nth-last-child(3) {
				transform: rotate(-45deg) translate(-11px, 11px);
			}

			&:checked ~ span:nth-last-child(2) {
				opacity: 0;
				transform: rotate(0deg) scale(0.2, 0.2);
			}

			&:checked .header__navbar {
				left: 0;
			}

		}

		& span {

			display: block;
			width: 23px;
			height: 3px;
			background: $white;
			margin-bottom: 5px;
			position: relative;
			z-index: 1;
			transform-origin: 4px 0px;
			transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
						background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
						opacity 0.55s ease;

			&:first-child {
				transform-origin: 0% 0%;
			}

			&:nth-last-child(2) {
				transform-origin: 0% 100%;
			}

		}

	}

	&__navbar {

		width: 95%;
		padding: 0;
		position: fixed;
		top: 69px;
		bottom: 0;
		left: -100%;
		right: 0;
		transition: left 0.5s ease;
		background: $blue;
		box-shadow: $menu-shadow;

		@media screen and (min-width: $tablet-landscape) {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			position: relative;
			top: auto;
			bottom: auto;
			left: 0;
			right: 0;
			padding: 0;
			box-shadow: none;
		}

		&--open {
			left: 0;
		}

	}

	&__nav-list {

		background: $blue;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: 0;
		padding: 0;

		@media screen and (min-width: $tablet-landscape) {
			background: transparent;
			overflow: visible;
			flex-direction: row;
		}

	}

	&__nav-item {

		position: relative;
		text-align: left;
		border-bottom: 1px solid $white;
		margin-bottom: 0;

		@media screen and (min-width: $tablet-landscape) {
			border-bottom: 0 none;
		}

		&--dropdown {

			& .header__nav-link {

				&:after {
					
					@media screen and (min-width: $tablet-landscape) {
						content: '';
						width: 12px;
						height: 12px;
						border-top: 1px solid $white;
						border-left: 1px solid $white;
						display: inline-block;
						font-size: 12px; 
						transform: rotate(-135deg) skew(0deg);
						overflow: hidden;
						margin-top: -10px;
						margin-left: 15px;
					}

				}
				
			}

			& .header__nav-link--active {

				@media screen and (min-width: $tablet-landscape) {

					&:after {
						border-top: 1px solid $light-blue;
						border-left: 1px solid $light-blue;
					}

				}
				
			}


			&:hover .header__dropdown {
				
				visibility: visible;
				opacity: 1;
				height: 100%;
				z-index: 10;

				@media screen and (min-width: $tablet-landscape) {
					height: auto;
				}

			}
	
		}

		&:last-of-type {

			& .header__nav-link {
				padding-right: 0;
			}

		}

	}

	&__nav-link {

		display: flex;
		flex: 1;
		justify-content: space-between;
		align-items: center;
		font-family: $font-content-bold;
		font-size: 18px;
		color: $white;
		line-height: 18px;
		padding: 20px;
		margin: 0;
		border: none;
		transition: all 0.25s ease;
		text-decoration: none;

		@media screen and (min-width: $tablet-landscape) {
			font-family: $font-content-regular;
			font-size: 16px;
		}

		&--active {

			@media screen and (min-width: $tablet-landscape) {

				color: $light-blue;

			}

		}
		
	}


	&__dropdown {

		display: none;

		@media screen and (min-width: $tablet-landscape) {
			list-style: none;
			display: inline-block;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top: 100%;
			left: 0;
			width: 200px;
			text-align: left;
			transition: opacity 0.3s ease;
			box-shadow: $menu-shadow;
		}

	}

	&__dropdown-item {

		background: $white;
		margin-bottom: 0;

		&:first-child {

			@media screen and (min-width: $tablet-landscape) {
				margin-top: 20px;
			}
			
		}

	}

	&__dropdown-link {

		display: block;
		font-family: $font-content-regular;
		font-size: 16px;
		color: $blue;
		line-height: 16px;
		padding: 20px;
		margin: 0;
		border: none;
		transition: all 0.25s ease;
		text-decoration: none;

		@media screen and (min-width: $tablet-landscape) {
			
		}

		&:hover {

			@media screen and (min-width: $tablet-landscape) {
				background: $light-blue;
				color: $white;
			}

		}

		&--active {

			@media screen and (min-width: $tablet-landscape) {
				border-bottom: 5px solid $orange;
			}

		}

	}

}
