
@import "../../../scss/main.scss";

.intro-card {

	display: flex;

	&__content {

		flex: 1;
		width: 100%;

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
			padding-right: 30px;
		}

	}

	&__image {

		display: none;

		@media screen and (min-width: $tablet-landscape) {
			display: block;
			width: 50%;
			overflow: hidden;
		}

		& img {
			width: 100%;
			height: auto;
		}

	}

}
