
@import '../../../scss/main.scss';

.counter {

	width: 100%;
	display: block;
	font-family: $font-content-bold;
	font-size: 28px;
	line-height: 34px;
	color: $white;
	letter-spacing: 0.02em;

	@media screen and (min-width: $tablet-portrait) {
		font-size: 40px;
		line-height: 44px;
	}

}
