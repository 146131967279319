@import '../../../scss/main.scss';

.timeline {

	&__wrapper {

		display: flex;
		padding: 55px 0;
		margin: 55px 0;
		position: relative;

		@media screen and (min-width: $tablet-landscape) {
			padding: 80px 0;
			margin: 80px 0;
		}

	}

	&__background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		display: flex;
	}

	&__background-left {
		flex: 1;
	}
	
	&__background-right {
		
		display: none;

		@media screen and (min-width: $desktop-medium) {
			display: block;
			background-color: $white;
			flex: 1;
		}

	}

	&__background-image {

		display: flex;
		justify-content: flex-end;
		background-image: $gradient-blue;
		background-blend-mode: soft-light, normal;
		width: 100%;
		height: 100%;

		@media screen and (min-width: $tablet-portrait) {
			display: block;
		}

		& img {

			width: auto;
			height: 100%;
			opacity: 0.5;
			mix-blend-mode: overlay;
			-webkit-filter: blur(10px);
			filter: blur(10px);

			@media screen and (min-width: $tablet-portrait) {
				width: 100%;
				height: auto;
			}

		}
	
	}

	&__content {
		display: flex;
		position: relative;
	}

	&__detail {
		display: flex;
		flex-direction: row;
		flex: 1;
		overflow: hidden;
	}

	&__image {

		display: none;

		@media screen and (min-width: $desktop-medium) {

			flex: 1;
			margin: -80px 0;
			overflow: hidden;
			display: flex;
			justify-content: flex-end;

			& img {
				height: 100%;
				width: auto;
			}

		}

	}

	&__slider {
		display: flex;
		padding-right: 20px;
	}

	&__card {

		display: flex;
		flex-direction: column;
		justify-content: space-between;

        
		@media screen and (min-width: $desktop-medium) {
			margin-right: 55px;
		}

	}

	&__card-item {

		&.in {
			opacity: 0;
			animation: expand 0.4s alternate ease-in forwards;
		}

	}

	&__instruction {

		& p {
			color: $white;
			margin-bottom: 65px;
		}
		
	}
	
	&__card-nav {
		display: flex;
		flex-direction: column;
	}

	&__nav {
		display: flex;
		justify-content: flex-start;
	}
	
}


.timeline__card {
    opacity: 1;
    transition: opacity 350ms;
    &.in {
        opacity: 0;
    }
}