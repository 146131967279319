
@import '../../../scss/main.scss';

.scroll-icon {

	&__mouse {
		width: 3px;
		  height: 20px;
		padding: 10px 8px;
		border: 2px solid #fff;
		border-radius: 25px;
		opacity: 0.75;
		box-sizing: content-box;
		margin-bottom: 35px;
	}

	&__scroller {
		width: 3px;
		height: 10px;
		border-radius: 25%;
		background-color: #fff;
		animation-name: scroll;
		animation-duration: 2.2s;
		animation-timing-function: cubic-bezier(.15,.41,.69,.94);
		animation-iteration-count: infinite;
	}

}

