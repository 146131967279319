
@import '../../../scss/main.scss';

.button {

	background-image: $btn-gradient-pink;
	padding: 18px 30px;
	font-family: $font-content-bold;
	font-size: 16px;
	color: $font-secondary;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-decoration: none;
	display: inline-block;
	transition: all .4s ease-in-out;
	cursor: pointer;

	&:hover {
		background-image: $btn-gradient-blue;
		transition: all .4s ease-in-out;
	}

	@media screen and (min-width: $tablet-portrait) {
		padding: 20px 50px;
	}

}
