
@import '../../../scss/main.scss';

.highlight-carousel {

	& .swiper-slide {
		transform: scale(0.92);
		padding: 10px;
		height: 100%;
		min-height: 650px;
	}

	& .swiper-slide-active {

		transform: scale(1);

		& .highlight-card__overlay {
			display: none;
		}

	}

}
