
@import '../../../scss/main.scss';

.modal {

	position: fixed;
	z-index: 999;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	transition: opacity 450ms ease-in;
	display: flex;
    justify-content: center;
	padding: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	background: $modal-opacity;

	@media screen and (min-width: $tablet-portrait) {
		padding: 60px;
	}

	&.hide {

		opacity: 0;
		transform:scale(0);
		
		.modal__background {
			display: none;
		}

	}

	&.show {

        z-index: 999;
		opacity: 1;
		transform:scale(1);
		
		.modal__background {
			display: block;
		}

	}

	&__content {

		position: relative;
		z-index: 10;
		padding: 40px 20px;
		width: 100%;
		max-width: 980px;

		@media screen and (min-width: $tablet-portrait) {
			padding: 40px;
		}

	}

	&__title {
		margin-bottom: 25px;
		color: $white;
		text-align: center;
	}

	&__description {
		padding-bottom: 60px;
		color: $white;
		text-align: center;
	}

	&__close {

		position: absolute;
		top: 20px;
		right: 20px;
        z-index: 1001;

		& button {

			position: relative;
			cursor: pointer;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: transparent;

			@media screen and (min-width: $tablet-portrait) {
				width: 60px;
				height: 60px;
			}

			&:hover {
				background-position: 100% 0;
				transition: all .4s ease-in-out;
			}

			&:before, 
			&:after {
				content: ' ';
				height: 23px;
				width: 2px;
				background-color: $white;

				@media screen and (min-width: $tablet-portrait) {
					height: 33px;
				}

			  }

			  &:before {
				transform: rotate(45deg);
			  }

			  &:after {
				transform: rotate(-45deg);
			  }

		}

	}

}

