
@import '../../../scss/main.scss';

.logo {

	width: auto;
	height: auto;
	display: block;
	
}
