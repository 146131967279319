
@import '../../../scss/main.scss';

.reports {

	&__scroll {
		
		height: 16px;
		overflow: hidden;
		margin: 40px 0;

		@media screen and (min-width: $tablet-portrait) {
			margin: 60px 0;
		}

	}

	&__nav {
		list-style: none;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		padding-top: 2px;
		height: 30px;
	}

	&__nav-item {

		font-family: $font-content-bold;
		font-size: 14px;
		line-height: 16px;
		color: $font-primary;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		padding-right: 20px;
		margin-right: 20px;
		border-right: 1px solid $border;
		cursor: pointer;
		white-space: nowrap;

		@media screen and (min-width: $tablet-portrait) {
			padding-right: 40px;
			margin-right: 40px;
			font-size: 16px;
			line-height: 18px;
		}

		&:last-of-type {
			border: 0 none;
		}

		&:hover {
			color: $white;
		}

		&--active {
			color: $white;
		}
		
	}

}
