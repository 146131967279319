
@import "../../../scss/main.scss";

.gallery-card {

	box-shadow: $card-shadow;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	@media screen and (min-width: $tablet-portrait) {
		width: calc(50% - 30px);
		margin: 0 15px 30px 15px;
	}

	@media screen and (min-width: $tablet-landscape) {
		width: calc(33% - 30px);
	}

	@media screen and (min-width: $desktop-small) {
		width: calc(25% - 30px);
	}

	&__thumbnail {

		& img {
			position: relative;
			z-index: 2;
			opacity: 0.6;
		}

	}

	&__thumbnail-overlay {
		width: 100%;
		height: 100%;
		background-color: $purple;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
	}

	&__icon {

		width: 80px;
		height: 80px;
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		transition: all .2s ease-in-out;
		cursor: pointer;

		&:hover {
			transform: translateX(-50%) translateY(-50%) scale(1.1);
		}

	}

	&__content {

		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 20;
		top:100%;
		transform:translateY(-60px);
		left: 0;
		transition: 0.4s ease-in;
		background-color: $blue;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;
		cursor: pointer;

		@media screen and (min-width: $tablet-landscape) {
			transform:translateY(-70px);
		}

		& h4 {

			color: $white;
			font-size: 14px;
			line-height: 18px;
			
			@media screen and (min-width: $tablet-landscape) {
				font-size: 16px;
				line-height: 20px;
			}

		}

		& p {
			font-size: 16px;
			line-height: 22px;
			display: none;
		}

		& .link {
			display: none;
		}

		&:hover {

			top: 0;
			transform:translateY(0);
			background-color: $white;
			justify-content: center;
			cursor: auto;
			mix-blend-mode: normal;

			& h4 {
				color: $font-title;
			}

			& p,
			& .link {
				display: block;
			}

		}

	}

}



