
@import '../../../scss/main.scss';

.link {

	font-family: $font-content-bold;
	font-size: 16px;
	line-height: 18px;
	color: $pink;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-decoration: none;
	display: inline-block;
	padding: 0;
	margin: 0;
	position: relative;

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: $pink;
		transition: width .3s;
	}

	&:hover {

		&:after {
			width: 0;
		}
		
	}

	&--download {

		padding-right: 25px;

		&:before {
			content: '';
			width: 14px;
			height: 18px;
			display: block;
			background: url(../../../assets/icons/icon-download.png);
			background-repeat: no-repeat;
			position: absolute;
			right: 0;
		}

	}

	&--email {

		font-family: $font-content-regular;
		text-transform: none;
		color: $font-primary;

		&:after {
			display: none;
		}

	}

}
