
@import '../../../scss/main.scss';

.tag {

	background: $gradient-orange;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	padding: 10px 10px 5px 20px;
	display: inline-block;
	font-family: $font-content-regular;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: $white;
	margin-left: 40px;

	@media screen and (min-width: $tablet-portrait) {
		font-size: 18px;
		line-height: 22px;
	}


}
