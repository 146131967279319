
@import '../../../scss/main.scss';

.footer {

	background: $blue;
	border-top: 1px solid $white;
	padding: 40px 20px;

	&__content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media screen and (min-width: $tablet-landscape) {
			align-items: center;
			flex-wrap: nowrap;
		}
	}

	&__logo {

		flex: 1;

		@media screen and (min-width: $tablet-landscape) {
			flex: 1 1 auto;
			order: 1;
			margin-right: 40px;
		}
	}

	&__nav {

		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		list-style: none;
		white-space: nowrap;

		@media screen and (min-width: $tablet-landscape) {
			flex: 1 1 100%;
			order: 3;
			flex-direction: row;
		}

	}

	&__nav-item {
		margin-bottom: 10px;
	}

	&__nav-link {

		font-family: $font-content-regular;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.05em;
		color: $white;
		text-decoration: none;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 16px;
			line-height: 16px;
			margin-left: 40px;
			padding-bottom: 4px;
		}

		&:hover {
			border-bottom: 1px solid $white;
		}

	}

	&__copyright {

		width: 100%;
		flex: 1 1 100%;
		font-family: $font-content-bold;
		font-size: 10px;
		line-height: 14px;
		letter-spacing: 0.1em;
		color: $font-utility;
		margin-top: 40px;

		@media screen and (min-width: $tablet-landscape) {
			flex: 1 1 auto;
			order: 2;
			margin-top: 0;
		}

	}

}
