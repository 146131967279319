
*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	background: $white;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

body {

	&.disable-scroll {
		overflow-y: hidden;
	}

}

*:focus {
	outline:none
}

h1, h2, h3 {
	font-weight: normal;
	letter-spacing: 0.05em;
}

h1 {

    font-family: $font-content-bold;
    font-size: 34px;
    line-height: 38px;
    color: $font-secondary;
    font-weight: normal;
	margin-bottom: 15px;

    @media screen and (min-width: $tablet-landscape) {
        font-size: 54px;
        line-height: 60px;
    }

}

h2 {
    
    font-family: $font-content-bold;
    font-size: 28px;
    line-height: 32px;
    color: $font-title;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet-landscape) {
        font-size: 40px;
		line-height: 44px;
		margin-bottom: 30px;
    }

}

h3 {
    
    font-family: $font-content-bold;
    font-size: 22px;
	line-height: 32px;
	letter-spacing: 0.05em;
    color: $font-title;
    margin-bottom: 5px;

    @media screen and (min-width: $tablet-landscape) {
        font-size: 30px;
        line-height: 38px;
    }

}

h4 {
    
    font-family: $font-content-bold;
    font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.02em;
    color: $font-title;
    margin-bottom: 15px;

    @media screen and (min-width: $tablet-landscape) {
        font-size: 22px;
        line-height: 30px;
    }

}

h5 {
    
    font-family: $font-content-regular;
    font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.02em;
    color: $orange;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet-landscape) {
        font-size: 18px;
        line-height: 30px;
    }

}



p {
	
    font-family: $font-content-regular;
    font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: $font-primary;
	margin-bottom: 20px;

	@media screen and (min-width: $tablet-landscape) {
        font-size: 18px;
		line-height: 30px;
		margin-bottom: 25px;
	}
	
}

b {
	font-family: $font-content-bold;
	font-weight: normal;
}

ul {
	
	margin: 0 0 0 15px;

	& li {
		margin-bottom: 5px;
		font-family: $font-content-regular;
		font-size: 14px;
		line-height: 26px;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 16px;
			line-height: 28px;
		}

	}
}

a {
	color: inherit;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

button {
	border: 0 none;
	box-shadow: none;
}
