
@keyframes scroll {

	0% { 
		opacity: 0; 
	}

	10% { 
		transform: translateY(0); opacity: 1; 
	}

	100% { 
		transform: translateY(15px); opacity: 0;
	}

}

@keyframes expand {

    0% { 
        transform: translateX(100%);
        opacity: 0;
	}

    100% { 
        transform: translateX(0px);
        opacity: 1;
    }

}

@keyframes fadeIn{

	0% {
	  opacity:0;
	}

	100% {
	  opacity:1;
	}
	
}

@keyframes pulse{

	0% {
	  transform: scale(1);
	}
	
	50% {
	  transform: scale(1.05);
	}
	
	100% {
	  transform: scale(1.1);
	}

}
