@import '../../../scss/main.scss';

.info-carousel {

	position: relative;

	&.content {

		@media screen and (min-width: $smartphone-landscape) {
			padding: 0;
		}

		@media screen and (min-width: $tablet-portrait) {
			padding: 0 100px;
		}

	}

	& .intro {

		@media screen and (min-width: $smartphone-landscape) {
			padding: 0 20px;
		}

		@media screen and (min-width: $tablet-portrait) {
			padding: 0;
		}

	}

	&__content {

		display: flex;
		flex-wrap: wrap;

		@media screen and (min-width: $smartphone-landscape) {
			display: block;
		}

	}

	& .swiper-slide {
		height: auto;
		padding: 10px;
	}

	& .swiper-button-prev,
	& .swiper-button-next {

		display: none;

		@media screen and (min-width: $smartphone-landscape) {
			display: flex;
		}

	}


}
