
@import '../../../scss/main.scss';

.breadcrumb {

	list-style: none;
	display: flex;
	padding-top: 20px;

	&__item {

		font-family: $font-content-regular;
		font-size: 11px;
		line-height: 11px;
		color: $font-breadcrumb;
		display: flex;
		margin-bottom: 0;

		&:after {
			content: '';
			width: 4px;
			height:6px;
			border: solid $font-breadcrumb;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 2px;
			transform: rotate(-45deg);
			margin: 1px 15px;
		}

		&--active {

			font-family: $font-content-bold;
			color: $light-blue;

			&:after {
				display: none;
			}

		}

	}

	&__nav-link {
		color: $font-breadcrumb;
		text-decoration: none;
	}
	
}
