
@import "../../../scss/main.scss";

.tab-card {

	background: $white;
	box-shadow: $card-shadow;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $tablet-landscape) {
		flex-direction: row;
	}

	&__content {

		flex: 1;
		width: 100%;
		padding: 40px 20px;

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
			padding: 40px;
		}

	}

	&__sub-title {
		font-family: $font-content-bold;
		color: $font-title;
	}

	&__image {

		@media screen and (min-width: $tablet-landscape) {
			display: block;
			width: 50%;
			overflow: hidden;
		}

		& img {
			width: 100%;
			height: auto;
		}

	}

}
