
@import '../../../scss/main.scss';

.range-slider {

	
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;

	&__timeline {
		
		display: flex;
		order: 3;
		height: 600px;

		@media screen and (min-width: $tablet-portrait) {
			order: 2;
			height: 700px;
		}

		@media screen and (min-width: $desktop-medium) {
			order: 2;
			height: 800px;
		}

	}


	@mixin rangeThumb {

		width: 12px;
		height: 12px;
		z-index: 20;
		cursor: pointer;
		border: 0 none;
		border-radius: 50%;
		background: $white;
		transition: all 0.2s;
		position: relative;
		top: 0;
		left: 8px;

		&:hover {
		  cursor: pointer;
		}

	  }
	  
	@mixin rangeTrack {
		width: 100%;
		height: 100%;
		cursor: pointer;
		margin: 0;
		position: relative;
		top: 0;
		transition: all 0.2s;
	}


	& input[type=range][orient=vertical] {
		
		z-index: 10;
		writing-mode: bt-lr;
   		-webkit-appearance: slider-vertical;
		background-color: transparent;
		transition: all 0.2s;
		width: 0;
		position: relative;
  
		
		&::-webkit-slider-thumb {
			-webkit-appearance:  none;
			@include rangeThumb;
		}

		&::-moz-range-thumb {
			@include rangeThumb;
			transform: translateY(0);
		}

		&::-ms-thumb {
			@include rangeThumb;
			cursor: pointer;
			top: 0;
			transform: translateY(0);
		}

		&::-webkit-slider-runnable-track {
			@include rangeTrack;
		}

		&::-moz-range-track {
			@include rangeTrack;
		}

		&::-ms-track { 
			@include rangeTrack;
			height: 100%;
			cursor: pointer;
			background: transparent;
			border-color: transparent;
		}

		&:focus { 
			outline: none;
		}

		&:before {
			content: '';
			height: 100%;
			width: 2px;
			display: block;
			background: $yellow;
			position: absolute;
			left: 50%;
			top: 0;
		}
		
	}

	&__label {

		display: flex;
		flex-direction: column;
		flex-direction: column-reverse;
		justify-content: space-between;

		&--left {
			order: 1;
		}

		&--right {

			order: 2;
			margin-left: -86px;

			@media screen and (min-width: $tablet-portrait) {
				order: 3;
				margin-left: 0;
			}
			
		}

	}

	&__label-item {

		font-family: $font-content-regular;
		color: $white;
		opacity: 0.4;
		font-size: 18px;
		margin: 10px 20px;

		&--active {
			font-family: $font-content-bold;
			color: $light-blue;
			opacity: 1;
		}

	}

	&__label-spacer {

		width: 30px;
		height: 35px;

		@media screen and (min-width: $tablet-portrait) {
			height: 30px;
		}

	}

}
