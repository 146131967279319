
@import '../../../scss/main.scss';

.gallery-carousel {

	& .swiper-button-prev,
	& .swiper-button-next {

		@media screen and (min-width: $tablet-portrait) {
			display:flex!important;
		}

	}

	&__gallery {

		position: relative;
		margin: 0 -20px 30px -20px;

		@media screen and (min-width: $tablet-portrait) {
			margin: 0 0 30px 0;
		}

		& .swiper-container {
			height: auto;
		}

		& .swiper-slide {

			width: auto;
			height: auto;
			display: flex;
			justify-content: center;
			padding: 0;

		}

	}
	

	&__thumbs {

		margin: 0 -20px 30px -20px;

		@media screen and (min-width: $tablet-portrait) {
			margin: 0 0 30px 0;
		}

		& .swiper-pagination {
			display: none;
		}

		& .swiper-wrapper {
			margin: 0 10px;
		}

		& .swiper-slide {

			width: 100px;
			height: 100px!important;
			padding: 0!important;
			overflow: hidden;
			display: flex;
			justify-content: center;

			& img {
				width: auto;
				height: 100%;
			}
		}

	}
	
}


