
@import '../../../scss/main.scss';

.gallery {

	position: relative;

	& .swiper-slide {
		height: auto;
		padding: 10px;
	}

	& .swiper-button-prev,
	& .swiper-button-next {

		@media screen and (min-width: $tablet-portrait) {
			display: none;
		}

	}

	& .content {

		padding: 0;

		@media screen and (min-width: $tablet-portrait) {
			padding: 0 100px;
		}

	}

	& .intro {

		padding: 0 20px;

		@media screen and (min-width: $tablet-portrait) {
			padding: 0;
		}

	}

	&__content {

		position: relative;

		@media screen and (min-width: $tablet-portrait) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
		}

	}

}
