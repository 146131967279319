
@import "../../../scss/main.scss";

.intro-banner {

	display: flex;
	height: 330px;
	justify-content: center;
	align-items: center;
	padding: 30px 0;
	overflow: hidden;
	position: relative;

	@media screen and (min-width: $tablet-portrait) {
		height: 380px;
	}

	&__content {

		flex: 1;
		width: 100%;
		position: relative;
		z-index: 20;

		@media screen and (min-width: $tablet-portrait) {
			width: 50%;
			padding-right: 30px;
		}

	}

	&__title {

		position: relative;

		&:before {

			@media screen and (min-width: $tablet-portrait) {
				content: '';
				width: 40px;
				height: 40px;
				background-image: url(../../../assets/icons/icon-plus.png);
				background-repeat: no-repeat;
				background-size: contain;
				display: block;
				position: absolute;
				top: -40px;
				left: -40px;
			}

			@media screen and (min-width: $tablet-landscape) {
				width: 50px;
				height: 50px;
				top: -50px;
				left: -50px;
			}

		}

	}

	&__text {

		font-size: 18px;
		line-height: 30px;
		color: $white;
		margin-bottom: 35px;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 22px;
			line-height: 30px;
		}

	}

	&__background {
		position: absolute;
		top: 0;
		z-index: 9;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__image {

		display: flex;
		justify-content: flex-end;
		background-image: $gradient-blue;
		background-blend-mode: soft-light, normal;
		width: 100%;
		height: 100%;

		@media screen and (min-width: $tablet-portrait) {
			display: block;
		}

		& img {

			width: auto;
			height: 100%;
			opacity: 0.5;
			mix-blend-mode: overlay;
			-webkit-filter: blur(10px);
			filter: blur(10px);

			@media screen and (min-width: $tablet-portrait) {
				width: 100%;
				height: auto;
			}

		}

	}

	&__pattern {

		max-width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@media screen and (min-width: $tablet-portrait) {
			max-width: 40%;
		}

		& img {

			width: 350%;
			height: auto;

			@media screen and (min-width: $tablet-portrait) {
				width: 300%;
				height: auto;
			}

			@media screen and (min-width: $tablet-landscape) {
				width: 200%;
				height: auto;
			}

			@media screen and (min-width: $desktop-medium) {
				width: 150%;
				height: auto;
			}
			

		}

	}

}
