
@import "../../../scss/main.scss";

.contact-form {

	&__fieldset {

		width: 100%;
		display: flex;
		flex-wrap: wrap;
		border: 0 none;
		margin-top: 55px;

		@media screen and (min-width: $tablet-portrait) {
			margin: 80px -15px 0 -15px;
		}

	}

	&__group {

		flex: 1 1 auto;
		width: 100%;
		margin: 0 0 35px 0;
		position: relative;

		@media screen and (min-width: $tablet-portrait) {
			width: calc(50% - 30px);
			margin: 0 15px 45px 15px;
		}

		&--full {
			flex: 1 1 100%;
			display: flex;
		}

		&--error {

			& .contact-form__label {
				color: $red;
			}

			& .contact-form__input,
			& .contact-form__textarea {
				color: $red;
				border-bottom: 2px solid $red;
			}
		}

		&--complete {

			& .contact-form__input,
			& .contact-form__textarea {
				color: $font-title;
				border-bottom: 2px solid $font-title;
			}

			&:after {
				content: '';
				position: absolute;
				right: 5px;
				bottom: 12px;
				display: inline-block;
				transform: rotate(45deg);
				height: 15px;
				width: 10px;
				border-bottom: 2px solid $green;
				border-right: 2px solid $green;
			}

		}

	}

	&__label {
		position: absolute;
		top: -2px;
		left: 5px;
		transition: all 200ms;
		font-family: $font-content-regular;
		font-size: 18px;
		line-height: 20px;
		color: $font-primary;
	}

	&__input,
	&__textarea {

		width: 100%;
		border: 0 none;
		border-bottom: 1px solid $font-primary;
		padding: 5px;
		font-family: $font-content-regular;
		font-size: 18px;
		line-height: 20px;
		color: $font-title;
		background-color: transparent;

		&:focus {
			color: $green;
			border-bottom: 2px solid $font-title;
		}

		&:focus + .contact-form__label,
		&:valid + .contact-form__label {
			font-size: 13px;
			transform: translate3d(0, -100%, 0);
			left: 0;
		}

	}

	&__error {
		font-family: $font-content-regular;
		font-size: 13px;
		line-height: 18px;
		color: $red;
		margin-top: 5px;
		display: block;
		height: 0;
	}

	&__info {

		display: flex;
		align-content: center;
		margin-bottom: 25px;

		& p {
			margin: 0;
		}

		&:before {
			content: '';
			width: 23px;
			height: 28px;
			display: block;
			background: url(../../../assets/icons/lock.svg);
			background-repeat: no-repeat;
			margin-right: 10px;
		}

	}


}
