
@import "../../../scss/main.scss";

.location {

	display: flex;
	flex-direction: column;

	@media screen and (min-width: $tablet-portrait) {
		flex-direction: row;
	}

	&__map {

		flex: 1;
		box-shadow: $card-shadow;
		min-height: 200px;
		margin-bottom: 40px;

		@media screen and (min-width: $tablet-portrait) {
			margin-bottom: 0;
		}

	}

	&__content {

		flex: 1;       

		@media screen and (min-width: $tablet-portrait) {
			padding-left: 30px;
		}

		& h5 {
			font-family: $font-content-bold;
			color: $font-title;
			margin-bottom: 5px;
		}

	}

	&__item {
		
        margin-bottom: 25px;
        
        span {
            display: block;
        }
	}

}
