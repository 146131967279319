
@import '../../../scss/main.scss';

.infographic {

	position: relative;

	&__areas-scroll {

		height: 58px;
		overflow: hidden;
		margin: 0 0 40px 0;
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background: $white;
			position: absolute;
			bottom: 0;
			z-index: 9;
		}

	}

	&__scroll {
		position: relative;
	}

	&__scroll-indicator {

		width: 15px;
		height: 15px;
		position: absolute;
		top: 20px;
		border-top: 2px solid $white;
  		border-left: 2px solid $white;

		&--prev {
			left: -40px;
			transform: rotate(-45deg);
		}

		&--next {
			right: -40px;
			transform: rotate(135deg);
		}

	}

	&__areas {
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		position: relative;
		height: 80px;
	}

	&__areas-item {

		font-family: $font-content-regular;
		font-size: 14px;
		text-transform: uppercase;
		color: $white;
		letter-spacing: 0.05em;
		padding: 20px 0 0 0;
		border-bottom: 2px solid transparent;
		position: relative;
		cursor: pointer;
		white-space: nowrap;

		@media screen and (min-width: $tablet-landscape) {
			font-size: 16px;
		}

		&:first-of-type {

			& span {
				padding-left: 0;

				@media screen and (min-width: $tablet-landscape) {
					padding-left: 35px;
				}

			}

		}

		& span {

			padding: 0 25px;
			border-right: 1px solid $white;

			@media screen and (min-width: $tablet-landscape) {
				padding: 0 35px;
			}

		}

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 4px;
			background: $pink;
			transition: width .3s;
			position: absolute;
			bottom: 20px;
			z-index: 10;
		}
	
		&:hover {
	
			&:after {
				width: 100%;
			}
			
		}

		&.active {
			
			&:after {
				width: 100%;
			}

		}

	}

	&__section {

		display: flex;
		flex-direction: column;
		margin-bottom: 40px;

		&--bottom {
			margin-bottom: 0;
		}

		@media screen and (min-width: $tablet-landscape) {
			flex-direction: row;
			align-items: flex-start;
		}

	}

	&__instruction {

		display: flex;
		margin-bottom: 40px;

		& p {
			margin-left: 30px;
			margin-bottom: 0;
		}

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
			margin-bottom: 0;
			margin-right: 40px;
		}

	}

	&__stages {

		display: flex;
		flex-direction: column;

		@media screen and (min-width: $tablet-portrait) {
			flex-direction: row;
		}

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
		}

	}

	&__stages-item {

		cursor: pointer;
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		font-family: $font-content-regular;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.02em;
		color: $white;
		text-align: center;

		@media screen and (min-width: $tablet-portrait) {
			flex-direction: column;
		}

		&.active {

			font-family: $font-content-bold;

			& .infographic__stages-state {
				
				&:after {
					background: $pink;
				}

				&:hover {

					&:after {
						transform: scale(1);
					}

				}

			}
		}

	}

	&__stages-state {

		position: relative;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		border: 1px solid $white;
		margin: 5px 15px 5px 0;

		@media screen and (min-width: $tablet-portrait) {
			height: 40px;
			width: 40px;
			border: 2px solid $white;
			margin: 0 15px 15px 15px;
		}

		&:after {
			position: absolute;
			content: '';
			top: 4px;
			left: 4px;
			height: calc(100% - 8px);
			width: calc(100% - 8px);
			border-radius: inherit;
			background: $white;
			z-index: 1;
			transform: scale(1);
			transition: all 350ms ease-in-out;
		}

		&:hover {

			&:after {
				transform: scale(1.4);
			}

		}

	}

	&__slides {

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
			margin-right: 40px;
			order: 1;
		}

	}

	&__slide-item-title  {

		font-family: $font-content-bold;
		font-size: 22px;
		line-height: 28px;
		color: $white;
		letter-spacing: 0.05em;
		margin-bottom: 25px;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 30px;
			line-height: 38px;
		}

		& span {
			font-family: $font-content-regular;
		}

	}

	&__slide-header {
		display: flex;
		margin-bottom: 20px;
	}

	&__slide-number {

		font-family: $font-content-bold;
		font-size: 70px;
		line-height: 70px;
		color: $yellow;
		letter-spacing: 0.05em;
		margin-right: 20px;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 90px;
			line-height: 90px;
		}

		@media screen and (min-width: $desktop-medium) {
			font-size: 110px;
			line-height: 110px;
			margin-right: 40px;
		}

	}

	&__slide-title {

		border-bottom: 1px solid $white;
		margin-bottom: 25px;
		padding-bottom: 5px;
		flex: 1;

		h2 {

			font-size: 22px;
			line-height: 28px;
			margin-bottom: 0;

			@media screen and (min-width: $tablet-portrait) {
				font-size: 30px;
				line-height: 30px;
				margin-bottom: 5px;
			}

		}

		h3 {

			font-size: 14px;
			line-height: 24px;
			margin-bottom: 0;

			@media screen and (min-width: $tablet-portrait) {
				font-size: 18px;
				line-height: 30px;
			}

		}

		& h2,
		& h3 {
			color: $yellow!important;
		}

	}

	&__nav {

		display: flex;
		justify-content: center;
		border-top: 1px solid $white;
		padding-top: 40px;
		margin-top: 40px;

		@media screen and (min-width: $tablet-landscape) {
			justify-content: flex-start;
		}

	}

	&__nav-prev {

		@media screen and (min-width: $tablet-landscape) {
			order: 2;
		}

		& .nav-button--prev {

			margin-right: 0;

			@media screen and (min-width: $tablet-landscape) {
				margin-right: 50px;
			}

		}

	}

	&__nav-next {

		@media screen and (min-width: $tablet-landscape) {
			order: 3;
		}

	}

	&__nav-counter {

		display: flex;
		align-items: center;
		margin: 0 25px;

		@media screen and (min-width: $tablet-landscape) {
			flex: 1;
			order: 1;
			margin: 0;
		}

		& .slide-counter__item {

			color: $white;
			border-left: 1px solid $white;

			&:first-of-type {
				border-left: 0 none;
			}

		}

	}

	&__quad {

		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;

		@media screen and (min-width: $tablet-landscape) {
			width: 50%;
			order: 2;
			margin-bottom: 0;
		}

	}

	&__quad-item {

		width: 50%;
		padding: 15px;
		position: relative;
		padding: 30px;
		
		@media screen and (min-width: $tablet-landscape) {
			padding: 30px;
		}

		&:first-of-type {
			border-right: 1px solid hsla(255,255,255,.2);
			border-bottom: 1px solid hsla(255,255,255,.2);
		}

		&:last-of-type {
			border-left: 1px solid hsla(255,255,255,.2);
			border-top: 1px solid hsla(255,255,255,.2);
			margin-top: -1px;
			margin-left: -1px;
		}

		&--active {

			& .infographic__quad-item-number {
				opacity: 1;
			}

			& .infographic__quad-item-image {
				 
				& img {
					mix-blend-mode: normal;
					opacity: 1;
				}

			}

		}

		&--1 {

			& .infographic__quad-item-number {
				bottom: 15px;
				right: 15px;
			}

		}

		&--2 {

			& .infographic__quad-item-number {
				bottom: 15px;
				left: 15px;
			}

		}

		&--3 {

			& .infographic__quad-item-number {
				top: 15px;
				right: 15px;
			}

		}

		&--4 {

			& .infographic__quad-item-number {
				top: 15px;
				left: 15px;
			}

		}

	}

	&__quad-item-image {

		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		& img {
			max-width: 180px;
			mix-blend-mode: luminosity;
			opacity: 0.4;
		}

	}

	&__quad-item-number {

		font-family: $font-content-bold;
		color: $white;
		font-size: 22px;
		line-height: 28px;
		letter-spacing: 0.05em;
		position: absolute;
		opacity: 0.2;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 30px;
			line-height: 38px;
		}

	}

	&__organisations-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__organisations-list-item {

		position: relative;
		font-family: $font-content-regular;
		font-size: 16px;
		line-height: 28px;
		color: $white;
		letter-spacing: 0.02em;
		opacity: 0;
		padding-left: 20px;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 18px;
			line-height: 30px;
		}

		&:before {
			content: '\2022';
			color: $yellow;
			margin-right: 15px;
			position: absolute;
			top: 0;
			left: 0;
		}

		&:nth-child(1) { 
			animation: expand 0.2s alternate ease-in forwards;
		}

		&:nth-child(2) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 0.5s;
		}

		&:nth-child(3) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 1s;
		}

		&:nth-child(4) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 1.5s;
		}

		&:nth-child(5) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 2s;
		}

		&:nth-child(6) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 2.5s;
		}

		&:nth-child(7) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 3s;
		}

		&:nth-child(8) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 3.5s;
		}

		&:nth-child(8) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 4s;
		}

		&:nth-child(10) { 
			animation: expand 0.2s alternate ease-in forwards;
			animation-delay: 4.5s;
		}

	}

	&__overall {

		& p {
			font-family: $font-content-regular;
			font-size: 14px;
			line-height: 20px;
			color: $white;
			margin-bottom: 10px;

			@media screen and (min-width: $tablet-portrait) {
				font-size: 16px;
				line-height: 22px;
			}

		}

	}

	&__overall-content {

		display: flex;
		flex-direction: column;

		@media screen and (min-width: $tablet-portrait) {
			flex-direction: row;
		}

		@media screen and (min-width: $tablet-landscape) {
			flex-direction: column;
		}

		@media screen and (min-width: $desktop-medium) {
			flex-direction: row;
		}

	}

	&__overall-counter {
		flex: 1;
		margin-bottom: 40px;
	}

	&__radial-graph {
		position: relative;
		display: flex;
		justify-content: center;
	}

	&__radial-graph-detail {

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& span {
			font-family: $font-content-regular;
			font-size: 16px;
			color: $white;
			margin-bottom: 10px;
		}

		& .counter {
			font-size: 16px;
			line-height: 16px;
			padding-bottom: 10px;
			border-bottom: 1px solid $yellow;
			text-align: center;
		}

	}

	&__radial-graph-percentage {
		font-family: $font-content-bold;
		font-size: 40px;
		color: $white;
		letter-spacing: 0.05em;
		margin: 10px 0;
	}

}

tspan{
    font-size: 11px;
}



