
@import '../../../scss/main.scss';

.carousel {

	& .swiper-pagination {

		bottom: 30px;

		@media screen and (min-width: $tablet-landscape) {
			bottom: 40px;
		}

		& .swiper-pagination-bullet {

			width: 12px;
			height: 12px;
			background: $background-opacity;
			border: 1px solid $white;
			opacity: 1;
			margin: 0 5px;

			@media screen and (min-width: $tablet-landscape) {
				width: 14px;
				height: 14px;
			}

		}

		& .swiper-pagination-bullet-active {
			background: $light-blue;
			border: 1px solid $light-blue;
		}

	}

}

.swiper-button-prev,
.swiper-button-next {

	width: 40px;
	height: 40px;
	background-image: $btn-gradient-pink;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all .4s ease-in-out;

	@media screen and (min-width: $tablet-portrait) {
		width: 60px;
		height: 60px;
	}

	&:hover {
		background-image: $btn-gradient-blue;
	}
	
	&:after {

		font-size: 15px;
		color: white;

		@media screen and (min-width: $tablet-portrait) {
			font-size: 18px;
		}

	}

	&.swiper-button-disabled {
		background-image: $btn-gradient-gray;
		background-size: 100% 100%;
		opacity: 1;
	}

}

.swiper-button-prev {
		
	left: 0;

	@media screen and (min-width: $tablet-portrait) {
		left: 15px;
	}

	@media screen and (min-width: $desktop-medium) {
		left: 30px;
	}

	&--outside {

		@media screen and (min-width: $tablet-portrait) {
			left: -85px;
		}

		@media screen and (min-width: $desktop-medium) {
			left: -85px;
		}

	}

}

.swiper-button-next {

	right: 0;

	@media screen and (min-width: $tablet-portrait) {
		right: 15px;
	}

	@media screen and (min-width: $desktop-medium) {
		right: 30px;
	}

	&--outside {

		@media screen and (min-width: $tablet-portrait) {
			right: -85px;
		}

		@media screen and (min-width: $desktop-medium) {
			right: -85px;
		}

	}

}


    .swiper-button-lock {opacity: 0 !important}
