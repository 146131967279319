
@import '../../../scss/main.scss';

.article-carousel {

	position: relative;

	&__content {
		position: relative;
	}

	& .swiper-slide {
		height: auto;
		padding: 10px;
	}

	&.content {

		padding: 0;

		@media screen and (min-width: $tablet-portrait) {
			padding: 0 100px;
		}

	}

	& .intro {

		padding: 0 20px;

		@media screen and (min-width: $tablet-portrait) {
			padding: 0;
		}

	}

}
