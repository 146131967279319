
@import "../../../scss/main.scss";

.highlight-card {

	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	@media screen and (min-width: $tablet-portrait) {
		flex-direction: row;
	}

	&__overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		opacity: 0.6;
		background-color: $purple;
		z-index: 1;
		top: 0;
		left: 0;
	}

	&__image {

		flex: 1 1 50%;
		position: relative;
		overflow: hidden;
		box-shadow: $card-shadow;

		& img {
			width:100%;
			height:100%;
			object-position: center;
			object-fit: cover;
		}

		& .tag {
			position: absolute;
			top: 20px;
			right: 0;

			@media screen and (min-width: $tablet-portrait) {
				top: 40px;
			}

		}

	}

	&__logo {

		position: absolute;
		bottom: 0;
		right: 0;
		background: $white;
		padding: 10px;
		border-top-left-radius: 10px;
		width: auto;

		& img {
			width: auto;
			height: auto;
		}

	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 1 50%;
		padding: 20px;
		text-align: left;
	}

	&__description {
		@media screen and (min-width: $tablet-portrait) {
			order: 1;
		}
	}

	&__counter {
		display: flex;
		justify-content: center;
		margin-bottom: 15px;

		@media screen and (min-width: $tablet-portrait) {
			order: 2;
			justify-content: start;
			margin-bottom: 0;
		}
	}
}


