
@import "../../../scss/main.scss";

.report-item {

	display: flex;
	flex-direction: column;
	padding: 18px;
	border-bottom: 1px solid $white;

	@media screen and (min-width: $tablet-portrait) {
		flex-direction: row;
		justify-content: space-between;
    	align-items: center;
	}
	
	&__title {

		color: $white;
		margin: 0 0 10px 0;

		@media screen and (min-width: $tablet-portrait) {
			margin: 0;
		}

	}

	&__link {
		display: flex;
		justify-content: flex-end;
	}

}
